<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h1 class="text-primary-1 fs-1 fw-bold">Admins</h1>
      <button
        class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          py-2
          px-4
        "
        @click="toggleAdminModal({ isOpen: true, status: 'add' })"
      >
        <i class="pi pi-plus me-2"></i>
        Add admin
      </button>
    </div>

    <basic-info-table-component v-if="currentTable === 'basic-info'" />
    <account-info-table-component v-if="currentTable === 'table-account'" />
  </div>
</template>

<script>
import useAdmins from "@/modules/admin/composables/Members/Admins/useAdmins";
import useAdminAddOrUpdate from "@/modules/admin/composables/Members/Admins/useAdminAddOrUpdate";

import OptionsTableComponent from "@/modules/admin/components/Members/OptionsTableComponent";
import BasicInfoTableComponent from "@/modules/admin/components/Members/Admins/BasicInfoTableComponent";
import AccountInfoTableComponent from "@/modules/admin/components/Members/Admins/AccountInfoTableComponent";
import { onBeforeMount } from "vue";

export default {
  name: "MemberAdminView",
  components: {
    OptionsTableComponent,
    BasicInfoTableComponent,
    AccountInfoTableComponent,
  },
  setup() {
    const { getInfoAdmins } = useAdmins();
    onBeforeMount(() => getInfoAdmins());

    const { toggleAdminModal } = useAdminAddOrUpdate();

    return {
      ...useAdmins(),
      toggleAdminModal,
    };
  },
};
</script>

