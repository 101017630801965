import { computed, ref } from "vue";
import { useStore } from "vuex";
import { ref as refDb, set, get , child,  onValue } from "firebase/database";
import database from "@/firebase/config";


const useAdmins = () => {
  const store = useStore();
  const setInfoStatusAdmins = () => {
    let { basicInfo } = store.getters["admins/getMembersAdmins"];
    basicInfo.data.forEach(admin => {
      const dbRef = refDb(
        database,
        "Admins/" + admin.token_firebase + "/status"
      );
      onValue(dbRef, async (snapshot) => {
        if (snapshot.val() === "offline") {
          admin.status = "offline";
        } else {
          get(
            child(dbRef, "Admins/" + admin.token_firebase + "/last_status")
          )
            .then((snapshot2) => {
              if (snapshot2.val() !== "online" && snapshot2.val() !== "offline") {
                admin.status = snapshot.val();
              } else admin.status = "online";
            })
            .catch((error) => {
              console.error(error);
            });
        }
        store.commit("admins/setAdminsBasicInfo", { data: basicInfo.data });
      });
    });
  }
  const getInfoAdmins = async () => {
    await store.dispatch("admins/getAdmins");
    setInfoStatusAdmins()
  };

  const tableOptions = ref([
    { label: "Basic info", value: "basic-info" },
    { label: "Account", value: "table-account" },
  ]);

  const currentTable = computed(
    () => store.getters["admins/getMembersAdmins"].currentTable
  );

  const changeCurrentTable = (currentTable) => {
    store.commit("admins/setCurrentTableAdmins", currentTable);
  };

  const changeTable = (option) => (currentTable.value = option);

  return {
    getInfoAdmins,
    currentTable,
    changeTable,
    tableOptions,
    changeCurrentTable,
  };
};

export default useAdmins;
