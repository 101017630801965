import { computed, ref } from "vue";
import { useStore } from "vuex";
import { FilterMatchMode } from "primevue/api";
import filterDateRanges from "@/modules/admin/helpers/filterDateRanges";

const useBasicInfoAdmin = () => {
  const store = useStore();

  const basicInfoTable = computed(() => {
    const admins = store.getters["admins/getMembersAdmins"].basicInfo;
    const datesRanges_ = admins.dateRanges ?? [];
    const hasDateRanges = admins.dateRanges.filter((date) => date).length === 2;
    if (hasDateRanges) {
      admins.data = filterDateRanges({
        datesRanges: datesRanges_,
        data: admins.data,
        fieldDate: "created_at",
      });
    }

    return admins;
  });

  const clearDateRanges = async () => {
    store.commit("admins/setAdminsBasicInfo", {  dateRanges : [] });
    await store.dispatch("admins/getAdmins")
  };

  const basicInfoHeader = computed(() => {
    return store.getters["admins/getMembersAdmins"].basicInfo.header;
  })
  const basicInfoFilters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    cellphone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    age: { value: "", matchMode: FilterMatchMode.IN },
    gender: { value: [], matchMode: FilterMatchMode.IN   },
    birth: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    phone_code_id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    time_zone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    status: { value: [], matchMode: FilterMatchMode.IN  },
  });

  const filterOptions = ref();
  const showFilterOptions = (e) => filterOptions.value.toggle(e);

  const isBasicInfoSearch = ref(false);
  const toggleBasicInfoSearch = () => {
    isBasicInfoSearch.value = !isBasicInfoSearch.value;
  };

  const selectAdmins = ref(null);

  const getListings = computed(() => {
    return store.getters["shared/getListings"];
  })

  return {
    getListings,
    basicInfoHeader,
    basicInfoTable,
    isBasicInfoSearch,
    toggleBasicInfoSearch,
    basicInfoFilters,
    filterOptions,
    showFilterOptions,
    selectAdmins,
    clearDateRanges,
  };
};

export default useBasicInfoAdmin;
