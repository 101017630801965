<template>
  <data-table
    :value="accountTable.data"
    :loading="accountTable.isLoading"
    :rowHover="accountTable.data.length > 0"
    :globalFilterFields="['name', 'email', 'role']"
    v-model:filters="accountInfoFilters"
    size="normal"
    dataKey="id"
    responsiveLayout="scroll"
    v-model:selection="selectEmails"
    @rowSelect="onSelectRow"
    @rowUnselect="onSelectRow"
    @rowSelectAll="onSelectAll"
    @rowUnSelectAll="unSelectAll"
    :paginator="true"
    :rowsPerPageOptions="[20, 30, 40, 50]"
    :rows="20"
    ref="tableAdminsAccount"
    exportFilename="admins-account"
    filterDisplay="menu"
  >
    <template #header>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <div class="d-flex">
          <options-table-component
            :tableOptions="tableOptions"
            :currentTable="currentTable"
            :changeTable="changeCurrentTable"
          />
          <div class="d-flex align-items-center gap-1">
            <input-text
              v-if="isAccountInfoSearch"
              placeholder="Search by name, email ..."
              class="rounded-0"
              v-model="accountInfoFilters['global'].value"
            />
            <i
              class="
                pi pi-search
                py-1
                px-3
                bg-primary-1
                text-white
                fs-5
                icon-search
                pointer
                border-r-10
                overflow-hidden
              "
              @click="toggleAccountInfoSearch"
            ></i>
          </div>
        </div>
      </div>
    </template>
    <template #empty> No admins found. </template>
    <template #loading> Loading admins data. Please wait. </template>
    <column selectionMode="multiple" headerStyle="width: 3rem"></column>

    <column
      v-for="(header, index) in accountHeader"
      :key="index"
      :field="header.field"
      :header="header.name"
      :sortable="header.sortable"
      style="min-width: 14rem"
       :showFilterMatchModes="false"
    >
      <template #body="{ data }">
        <p v-if="header.field !== 'role'">{{ data[header.field] }}</p>
        <div
          class="px-2 py-1 w-content rounded-pill"
          v-if="header.field === 'role'"
          :class="[roleUsers[data.role].class, roleUsers[data.role].classText]"
        >
          {{ data.role }}
        </div>
      </template>
        <template #filter="{ filterModel }" v-if="header.filter">
          <div class="d-block" v-if="header.field === 'role'">
            <div class="d-flex align-items-center mb-1 gap-2">
              <Checkbox name="role" value="Coordinator" v-model="filterModel.value" />
              <label>Coordinator</label>
            </div>
            <div class="d-flex align-items-center mb-1 gap-2">
              <Checkbox name="role" value="Admin" v-model="filterModel.value" />
              <label>Admin</label>
            </div>
          </div>
        </template>
    </column>
    <column header="Options" style="min-width: 14rem">
      <template #body="{ data }">
        <button
          class="
            btn
            text-primary-1 text-hover-primary-1
            bg-hover-primary-6
            border-primary-1
          "
          @click="getInfoAdmin({ id: data.id, status: 'edit' })"
        >
          Edit profile
        </button>
      </template>
    </column>
    <template #paginatorstart>
      <div class="d-flex align-items-center justify-content-start gap-3">
        <Button
          class="p-button-rounded bg-primary-1 text-white border-0 m-0"
          icon="pi pi-send"
          v-tooltip.top="'Send email'"
          :disabled="selectEmails.length === 0"
          @click="() => emailModal(true)"
          style="width: 2.4rem"
        />
        <Button
          class="p-button-rounded bg-primary-1 text-white border-0 m-0"
          icon="pi pi-refresh"
          v-tooltip.top="'Reset password'"
          :disabled="selectEmails.length === 0"
          @click="() => cancelEmailResetModal(true)"
          style="width: 2.4rem"
        />
        <Button
          class="p-button-rounded bg-primary-1 text-white border-0 m-0"
          icon="pi pi-sort-alt"
          v-tooltip.top="'Export report'"
          style="width: 2.4rem"
          @click="() => exportCsv('admins-account')"
          :disabled="loadingExport"
        />
          <p v-if="selectEmails.length > 0">{{selectEmails.length}} selected from {{accountTable.data.length}} records</p>
      </div>
    </template>
  </data-table>
  <overlay-panel
    ref="filterOptions"
    style="width: 200px"
    class="filter-options border-primary-1 bg-primary-4"
  >
    <div
      class="
        form-check
        d-flex
        gap-3
        align-items-center
        justify-content-start
        w-100
        option
      "
    >
      <input class="form-check-input" type="checkbox" />
      <label class="form-check-label"> Reporte 1 </label>
    </div>
    <div
      class="
        form-check
        d-flex
        gap-3
        align-items-center
        justify-content-start
        w-100
        option
      "
    >
      <input class="form-check-input" type="checkbox" />
      <label class="form-check-label"> Reporte 2 </label>
    </div>
  </overlay-panel>
  <export-excel
    class="btn btn-default d-none account-admins"
    :data="dataExcel"
    :fields="fieldsExcelAccountAdmins"
    worksheet="My Worksheet"
    name="filename.xls"
  >
  </export-excel>
</template>


<script>
import useAccountInfoAdmin from "@/modules/admin/composables/Members/Admins/useAccountInfoAdmin";
import useAdminAddOrUpdate from "@/modules/admin/composables/Members/Admins/useAdminAddOrUpdate";
import useAdmins from "@/modules/admin/composables/Members/Admins/useAdmins";
import OptionsTableComponent from "@/modules/admin/components/Members/OptionsTableComponent";
import roleUsers from "@/shared/data/rolesUsers";
import useResetEmail from "@/modules/admin/composables/Members/useResetEmail";
import useSendEmail from "@/modules/admin/composables/Members/useSendEmail";
import useMembers from "@/modules/admin/composables/Members/useMembers";

export default {
  name: "AccountTableComponent",
  components: {
    OptionsTableComponent,
  },
  setup() {
    const { getInfoAdmin } = useAdminAddOrUpdate();
    return {
      ...useMembers(),
      ...useSendEmail(),
      ...useResetEmail(),
      ...useAdmins(),
      ...useAccountInfoAdmin(),
      roleUsers,
      getInfoAdmin,
    };
  },
};
</script>