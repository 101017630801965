import { computed, ref } from "vue";
import { useStore } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";

const useAccountInfoAdmin = () => {
  const store = useStore();

  const accountTable = computed(
    () => store.getters["admins/getMembersAdmins"].account
  );
  const accountHeader = ref(accountTable.value.header);
  const accountInfoFilters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
    email: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
    role: { value: [], matchMode: FilterMatchMode.IN   },

  });
  const filterOptions = ref();
  const showFilterOptions = (e) => filterOptions.value.toggle(e);
  const rangeDates = ref();

  const isAccountInfoSearch = ref(false);
  const toggleAccountInfoSearch = () => {
    isAccountInfoSearch.value = !isAccountInfoSearch.value;
  };

  const selectAdmins = ref(null);

  const getListings = computed(() => {
    return store.getters["shared/getListings"];
  })

  return {
    getListings,
    accountTable,
    accountHeader,
    accountInfoFilters,
    filterOptions,
    showFilterOptions,
    rangeDates,
    isAccountInfoSearch,
    toggleAccountInfoSearch,
    selectAdmins,
  };
};

export default useAccountInfoAdmin;
