<template>
  <data-table
    :value="basicInfoTable.data"
    :loading="basicInfoTable.isLoading"
    :rowHover="basicInfoTable.data.length > 0"
    :globalFilterFields="[
      'name',
      'email',
      'cellphone',
      'age',
      'gender',
      'birth',
      'phone_code_id',
      'timezone',
      'status',
    ]"
    :rowsPerPageOptions="[20, 30, 40, 50]"
    size="normal"
    ref="tableAdminsBasicInfo"
    dataKey="id"
    responsiveLayout="scroll"
    v-model:filters="basicInfoFilters"
    v-model:selection="selectEmails"
    @rowSelect="onSelectRow"
    @rowUnselect="onSelectRow"
    @rowSelectAll="onSelectAll"
    @rowUnSelectAll="unSelectAll"
    exportFilename="admins-basic-info"
    filterDisplay="menu"
    :paginator="true"
    :rows="20"
    v-if="getListings.countrycodes.data.length"
  >
    <template #header>
      <div class="d-flex align-items-center justify-content-between mb-3">
        <div class="d-flex">
          <options-table-component
            :tableOptions="tableOptions"
            :currentTable="currentTable"
            :changeTable="changeCurrentTable"
          />
          <div class="d-flex align-items-center gap-1">
            <input-text
              v-if="isBasicInfoSearch"
              placeholder="Search by name, email ..."
              class="rounded-0"
              v-model="basicInfoFilters['global'].value"
            />
            <i
              class="
                pi pi-search
                py-1
                px-3
                bg-primary-1
                text-white
                fs-5
                icon-search
                pointer
                border-r-10
                overflow-hidden
              "
              @click="toggleBasicInfoSearch"
            ></i>
          </div>
        </div>

        <div class="d-flex gap-3">
          <div class="field-outline-blue w-100">
            <Calendar
              v-model="basicInfoTable.dateRanges" dateFormat="M dd"
                selectionMode="range" :manualInput="false" :showIcon="true" placeholder="MM/DD - MM/DD"
                :showButtonBar="true" @clear-click="clearDateRanges"
            />
          </div>
        </div>
      </div>
    </template>
    <template #empty> No admins found. </template>
    <template #loading> Loading admins data. Please wait. </template>
    <column selectionMode="multiple" headerStyle="width: 3rem"></column>
    <column
      v-for="(header, index) in basicInfoHeader"
      :key="index"
      :field="header.field"
      :header="header.name"
      :sortable="header.sortable"
       :showFilterMatchModes="false"
      style="min-width: 14rem"
    >
      <template #body="{ data }">
        <div v-if="header.field === 'name'">
          {{ data['name'] + ' ' + data['last_name']}}
          <br>
          <button
            type="button"
            class="btn text-secondary-1 text-hover-secondary-1 p-0 pt-1 px-0"
            @click="getInfoAdmin({ id: data.id, status: 'show-info' })"
          >
            See profile
          </button>
        </div>
        <p
          v-else-if="
            header.field !== 'status' &&
            header.field !== 'cellphone' &&
            header.field !== 'birth'
          "
        >
          {{ data[header.field] }}
        </p>
        <p v-if="header.field === 'cellphone' && data['cellphone']">
          {{ getListings.countrycodes.data.find(item => item.id == data["phone_code_id"]).dial_code + " " + data[header.field] }}
        </p>
        <p v-if="header.field === 'birth' && data['birth']">
          {{ formatDate(data["birth"]) }}
        </p>

        <div
          class="
            bg-white
            py-1
            px-3
            bl-shadow
            d-flex
            gap-2
            rounded-pill
            w-content
            align-items-center
          "
          v-if="header.field === 'status'"
        >
          <div
            class="status"
            v-if="statusUsers[data.status]"
            :class="
              statusUsers[data.status] ? statusUsers[data.status].class : ''
            "
          ></div>
          <span class="text-neutro-1">{{
            statusUsers[data.status]
              ? statusUsers[data.status].text
              : "Loading .."
          }}</span>
        </div>
      </template>
      
       <template #filter="{ filterModel }" v-if="header.filter">
          <div class="d-block" v-if="header.field === 'status'">
            <div class="d-flex align-items-center mb-1 gap-2">
              <Checkbox name="status" value="online" v-model="filterModel.value" />
              <label>Online</label>
            </div>
            <div class="d-flex align-items-center mb-1 gap-2">
              <Checkbox name="status" value="offline" v-model="filterModel.value" />
              <label>Offline</label>
            </div>
          </div>
           <div class="d-block" v-if="header.field === 'gender'">
            <div v-for="(gender,index) in getListings.genders" :key="index" class="d-flex align-items-center mb-1 gap-2">
              <Checkbox name="status" :value="gender.value" v-model="filterModel.value" />
              <label>{{gender.value}}</label>
            </div>
          </div>
          <div class="d-block" v-if="header.field === 'age'">
            <div class="d-block">
              <input-text type="number" name="age" v-model="filterModel.value" placeholder="Search by age"/>
            </div>
          </div>
           
        </template>
    </column>
    <template #paginatorstart>
      <div class="d-flex align-items-center justify-content-start gap-3">
        <Button
          class="p-button-rounded bg-primary-1 text-white border-0 m-0"
          icon="pi pi-send"
          v-tooltip.top="'Send email'"
          :disabled="selectEmails.length === 0"
          @click="() => emailModal(true)"
          style="width: 2.4rem"
        />
        <Button
          class="p-button-rounded bg-primary-1 text-white border-0 m-0"
          icon="pi pi-refresh"
          v-tooltip.top="'Reset password'"
          :disabled="selectEmails.length === 0"
          @click="() => cancelEmailResetModal(true)"
          style="width: 2.4rem"
        />
        <Button
          class="p-button-rounded bg-primary-1 text-white border-0 m-0"
          icon="pi pi-sort-alt"
          v-tooltip.top="'Export report'"
          style="width: 2.4rem"
          @click="() => exportCsv('admins-basic-info')"
          :disabled="loadingExport"
        />
          <p v-if="selectEmails.length > 0">{{selectEmails.length}} selected from {{basicInfoTable.data.length}} records</p>

      </div>
    </template>
  </data-table>
  <overlay-panel
    ref="filterOptions"
    style="width: 200px"
    class="filter-options border-primary-1 bg-primary-4"
  >
    <div
      class="
        form-check
        d-flex
        gap-3
        align-items-center
        justify-content-start
        w-100
        option
      "
    >
      <input class="form-check-input" type="checkbox" />
      <label class="form-check-label"> Reporte 1 </label>
    </div>
    <div
      class="
        form-check
        d-flex
        gap-3
        align-items-center
        justify-content-start
        w-100
        option
      "
    >
      <input class="form-check-input" type="checkbox" />
      <label class="form-check-label"> Reporte 2 </label>
    </div>
  </overlay-panel>
  <export-excel
    class="btn btn-default d-none basic-admins"
    :data="dataExcel"
    :fields="fieldsExcelBasicAdmins"
    worksheet="My Worksheet"
    name="filename.xls"
  >
  </export-excel>
</template>

<script>
import useBasicInfoAdmin from "@/modules/admin/composables/Members/Admins/useBasicInfoAdmin";
import useAdminAddOrUpdate from "@/modules/admin/composables/Members/Admins/useAdminAddOrUpdate";
import useAdmins from "@/modules/admin/composables/Members/Admins/useAdmins";
import OptionsTableComponent from "@/modules/admin/components/Members/OptionsTableComponent";
import statusUsers from "@/shared/data/statusUsers";
import formatDate from "@/shared/utils/dates/formatDate";
import useResetEmail from "@/modules/admin/composables/Members/useResetEmail";
import useSendEmail from "@/modules/admin/composables/Members/useSendEmail";
import useMembers from "@/modules/admin/composables/Members/useMembers";

export default {
  name: "BasicInfoTableComponent",
  components: {
    OptionsTableComponent,
  },
  setup() {
    const { getInfoAdmin } = useAdminAddOrUpdate();
    return {
      ...useResetEmail(),
      ...useMembers(),
      ...useSendEmail(),
      ...useBasicInfoAdmin(),
      ...useAdmins(),
      statusUsers,
      getInfoAdmin,
      formatDate,
    };
  },
};
</script>
